import React, { Fragment } from "react";
import PageHeader from "../components/PageHeader";
import Layout from "../components/Layout";
import Facts from "../components/Facts";
import { Link } from "@reach/router";

export const ServicesPageTemplate = ({
  title,
  solutionsHeading,
  solutionsDesc,
  services,
  displaysHeading,
  displaysDesc,
  displaySizes
}) => (
  <Fragment>
    <PageHeader title={title} paintColor={"blue"} />
    <div id="services-splatter">
      <div className="container">
        <div className="columns">
          <div className="column is-10 is-offset-1">
            <section className="section">
              <div className="has-text-centered">
                <h3 className="is-size-2 title-underline">
                  {solutionsHeading}
                </h3>
                <p className="is-size-5 has-margin-bot-3">{solutionsDesc}</p>
                <div className="columns is-multiline">
                  {services.map((service, index) => (
                    <div key={index} className="column is-4">
                      <div className="service-block">
                        <img
                          src={service.image.childImageSharp.fluid.src}
                          style={{
                            width: "8em",
                            height: "8em"
                          }}
                        />
                        <h2 className="is-size-3">{service.title}</h2>
                        <p className="is-size-5">{service.text}</p>

                        <Link
                          className="btn btn-outline"
                          to={`/services/${service.linksto}`}
                        >
                          {service.linktext}
                        </Link>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </section>
            <section className="section">
              <div className="has-text-centered">
                <h3 className="is-size-2 title-underline">{displaysHeading}</h3>
                <p className="is-size-3 has-margin-bot-3">{displaysDesc}</p>
              </div>
              <div className="columns is-multiline">
                {displaySizes.map((display, index) => (
                  <div key={index} className="column is-4">
                    <div className="display-size">
                      <h3 className="has-text-white is-size-1">
                        {display.dimension}
                      </h3>
                      <Link
                        className="btn btn-outline-white"
                        to={`/${display.linksto}`}
                      >
                        {display.linktext}
                      </Link>
                    </div>
                  </div>
                ))}
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>

    <div className="has-text-centered">
      <h3 className="is-size-2 title-underline">Why Choose Us?</h3>
    </div>
    <Facts />
  </Fragment>
);

const ServicesPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout>
      <ServicesPageTemplate
        title={frontmatter.title}
        solutionsHeading={frontmatter.solutions.heading}
        solutionsDesc={frontmatter.solutions.description}
        services={frontmatter.solutions.service}
        displaysHeading={frontmatter.displays.heading}
        displaysDesc={frontmatter.displays.description}
        displaySizes={frontmatter.displays.sizes}
      />
    </Layout>
  );
};

export default ServicesPage;

export const pageQuery = graphql`
  query ServicesPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "services-page" } }) {
      frontmatter {
        title
        solutions {
          heading
          description
          service {
            image {
              childImageSharp {
                fluid(maxWidth: 240, quality: 64) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            title
            text
            linksto
            linktext
          }
        }
        displays {
          heading
          description
          sizes {
            dimension
            linksto
            linktext
          }
        }
      }
    }
  }
`;
